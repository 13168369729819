import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Section, {Row, Col} from '../../../../components/ui/wrapper'
import Counter from '../../../../components/counter/layout-one'

const FunFactArea = ({sectionStyle, counterStyle}) => {
    const funFactQueryData = useStaticQuery(graphql `
        query GloablFunFactQuery {
            allFunFactJson(sort: {order: DESC, fields: allFunFact___sortOrder}, filter: {allFunFact: {elemMatch: {sortOrder: {eq: 4}}}}) {
                edges {
                  node {
                    count
                    title
                    allFunFact {
                      id
                      sortOrder
                      count
                      fact_supertext
                      fact_suffix
                      title
                    }
                  }
                }
            }
        }
    `);

    const data = funFactQueryData.allFunFactJson.edges;
    return(
        <Section {...sectionStyle}>
            {data.map((funfactRows,funFactRowNumIndex) => (
                <Row>
                    {funfactRows.node.allFunFact.map((item,i) => {
                        if(i > 3) {
                            return null;
                        }
                        return (
                            <Col md={3} sm={6} key={`fun-fact-${i}`}>
                                <Counter
                                    {...counterStyle}
                                    text={item.title}
                                    countTo={item.count}
                                    factSuperText={item.fact_supertext}
                                    factSuffix={item.fact_suffix}
                                />
                            </Col>
                        )
                    }
                    )}
                </Row>
            ))}
        </Section>
    )
}

FunFactArea.propTypes = {
    sectionStyle: PropTypes.object
}

FunFactArea.defaultProps = {
    sectionStyle: {
        pt: '59px',
        pb: '29px',
        bgColor: '#4b1e79'
    },
    counterStyle: {
        wrapperStyle: {
            mb: '30px'
        },
        countToStyle: {
            fontSize: '56px',
            fontweight: 700,
            color: '#fff',
            lineHeight: 1,
            mb: '20px'
        },
        textStyle: {
            color: '#ffffffb3'
        }
    }
}

export default FunFactArea;