import React from 'react';
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import {
    VideoButtonWrap,
    VideoLink,
    VideoContent,
    VideoButtonInner,
    VideoMark,
    VideoPlayWrap,
    VideoPlay,
    VideoPlayIcon,
    VideoText} from './video-button.style'

const VideoButton = ({text, onClick, ...restProps}) => {
    return (
        <VideoButtonWrap {...restProps}>
            <VideoLink onClick={onClick}>
                <VideoContent>
                    <VideoButtonInner>
                        {restProps.wave !== "false" && (
                            <VideoMark>
                                <div className="wave-pulse wave-pulse-1"></div>
                                <div className="wave-pulse wave-pulse-2"></div>
                            </VideoMark>
                        )}
                        <VideoPlayWrap>
                            <VideoPlay>
                                <VideoPlayIcon></VideoPlayIcon>
                            </VideoPlay>
                        </VideoPlayWrap>
                    </VideoButtonInner>
                    {text && <VideoText>{parse(text)}</VideoText>}
                </VideoContent>
            </VideoLink>
        </VideoButtonWrap>
    )
}

VideoButton.propTypes = {
    skin: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'dark',
        'gradient',
        'transparent'
    ])
}

VideoButton.defaultProps = {
    skin: 'light'
}


export default VideoButton;