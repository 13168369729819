import styled from 'styled-components';
import {device} from '../../../theme';
import Heading from '../../../components/ui/heading';
import {Row} from '../../../components/ui/wrapper';

export const OurMissionWrap = styled.div `
    margin: 50px;

    @media ${device.small}{
        margin-top: 25px;
    }
`;
 
export const OurVisionHeadingRow=styled(Row)`
    justify-content: center;
    padding: 24px;
    margin-bottom: 10px;
`

export const OurVisionHeading = styled(Heading) `
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 0;
    a{
        color: inherit;
        font-size: inherit;
    }
    span{
        color: ${props => props.theme.colors.themeColor};
    }
`;

export const OurVisionImageWrap = styled.div `
    position: relative;
    img{
        border-radius: 5px;
    }
`;

export const OurVisionContentWrap = styled.div `
    @media ${device.medium}{
        margin-top: 30px;
    }
    
    p   {
            span{
                color: ${props => props.theme.colors.themeColor};
        }
    }
`;

export const VideoBtnWrap = styled.div `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const ButtonWrap = styled.div `
    margin-top: 40px;
`;