import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    TestimonialWrap,
    TestimonialInfo,
    TestimonialMedia,
    TestimonialAuthor,
    TestimonialRating,
    AuthorInfo,
    AuthorName,
    AuthorOrgDetails,
    AuthorRole,
    AuthorOrganisation,
    Review} from './testimonial.style'

const Testimonial = ({authorName, authroRole, authorImg, rating, review, authorOrganisation, orgUrl, ...testimonialStyle}) => {
    const {wrapperStyle, infoStyle, imageStyle, reviewStyle} = testimonialStyle;
    const ratingItems = [];
    const disableRating = [];
    for(let i=1; i <= rating; i++){
        ratingItems.push(<i className="rating fa fa-star" key={`rating-${i}`}></i>)
    }

    if(rating < 5){
        for(let i=1; i <= 5 - rating; i++){
            disableRating.push(<i className="rating disable fa fa-star" key={`disable-rating-${i}`}></i>)
        }
    }
    const image = getImage(authorImg);
    const authorImage = <GatsbyImage image={image} alt={authorName} />

    return (
        <TestimonialWrap {...wrapperStyle}>
            <TestimonialInfo {...infoStyle}>
                {authorImg && (
                    <TestimonialMedia {...imageStyle}>
                        {authorImage}
                    </TestimonialMedia>
                )}
                <TestimonialAuthor>
                    {rating && (
                        <TestimonialRating>
                            {ratingItems}
                            {disableRating}
                        </TestimonialRating>
                    )}
                    <AuthorInfo>
                        {authorName && <AuthorName>{authorName}</AuthorName>}
                        <AuthorOrgDetails>
                            {authroRole && <AuthorRole>{authroRole}</AuthorRole>}
                            { orgUrl && (<a href={orgUrl} target="_blank">
                                {authorOrganisation && <AuthorOrganisation>{authorOrganisation}</AuthorOrganisation>}
                            </a>)}
                            {authorOrganisation && !orgUrl && <AuthorOrganisation>{authorOrganisation}</AuthorOrganisation>}
                        </AuthorOrgDetails>
                    </AuthorInfo>
                </TestimonialAuthor>
            </TestimonialInfo>
            {review && <Review {...reviewStyle}>{review}</Review>}
        </TestimonialWrap>
    )
}

Testimonial.propTypes = {
    authorImg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    rating: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    authorName: PropTypes.string,
    authroRole: PropTypes.string,
    review: PropTypes.string,
    testimonialStyle: PropTypes.object
}

Testimonial.defaultProps = {
    imageStyle: {
        circle: true
    }
}

export default Testimonial;
