import React, { useState } from "react";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col } from "../../../components/ui/wrapper";
import Text from "../../../components/ui/text";
import {
  OurMissionWrap,
  OurVisionHeadingRow,
  OurVisionHeading,
  OurVisionImageWrap,
  OurVisionContentWrap
} from "./vision.style";
import Image from '../../../components/image';

const OurVision = ({ textStyle }) => {
  const visionQueryData = useStaticQuery(graphql`query VisionDataQuery {
  aboutUsJson(id: {eq: "appointment-about-us"}) {
    our_vision {
      title
      text
      side_image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 570, height: 560, layout: CONSTRAINED)
        }
      }
    }
  }
}
`);

  const { our_vision } = visionQueryData.aboutUsJson;

  return (
    <OurMissionWrap>
      <OurVisionHeadingRow>
        <OurVisionHeading>{parse(our_vision.title)}</OurVisionHeading>
      </OurVisionHeadingRow>
      <Row>
        <Col lg={6}>
          <OurVisionImageWrap>
            <Image image={our_vision.side_image.childImageSharp.gatsbyImageData} alt="Our Vision" align="left"/>
          </OurVisionImageWrap>
        </Col>
        <Col lg={{span: 5, offset: 1}}>
          <OurVisionContentWrap>
            <Text
              {...textStyle}
              dangerouslySetInnerHTML={{ __html: our_vision.text }}
            />
          </OurVisionContentWrap>
        </Col>
      </Row>
    </OurMissionWrap>
  );
};

OurVision.propTypes = {
  textStyle: PropTypes.object
};

OurVision.defaultProps = {
  textStyle: {
    fontSize: "18px",
    mb: "10px"
  }
};

export default OurVision;
