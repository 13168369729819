import React, {useState} from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby"
import {Row, Col} from '../../../components/ui/wrapper'
import VideoButton from '../../../components/ui/video-button'
import ModalVideo from '../../../components/ui/modal-video'
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import List, {ListItem} from '../../../components/ui/list'
import Image from '../../../components/image'
import {OurMissionWrap, OurMissionHeadingRow, OurMissionHeading, OurMissionImageWrap, MissionContentWrap, VideoBtnWrap, ButtonWrap} from './our-mission.style'

const OurMission = ({textStyle, buttonStyle}) => {
    const aboutQueryData = useStaticQuery(graphql `query AboutDataQuery {
  aboutUsJson(id: {eq: "appointment-about-us"}) {
    about_content {
      link
      list
      title
      text
      side_image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 570, height: 560, layout: CONSTRAINED)
        }
      }
    }
  }
}
`)
    const [videoOpen, setVideoOpen] = useState(false);
    const modalVideoOpen = () => {
        setVideoOpen(true)
    }

    const modalVideoClose = () => {
        setVideoOpen(false)
    }

    const {about_content} = aboutQueryData.aboutUsJson;
    /* const {video_link} = about_content;
    const video_arr = video_link.split('=', -1);
    const video_id = video_arr[1]; */
  
    return (
        <OurMissionWrap>
            <OurMissionHeadingRow>
                {about_content.title && <OurMissionHeading>{parse(about_content.title)}</OurMissionHeading>}
            </OurMissionHeadingRow>
            <Row>
                <Col lg={6}>
                    <OurMissionImageWrap>
                        <Image fluid image={about_content.side_image.childImageSharp.gatsbyImageData} alt="Our Mission" align="left"/>
                        {/* <VideoBtnWrap>
                            <VideoButton skin="primary" onClick={modalVideoOpen}/>
                        </VideoBtnWrap> */}
                    </OurMissionImageWrap>
                </Col>
                <Col lg={{span: 5, offset: 1}}>
                    <MissionContentWrap>
                        {about_content.text && <Text {...textStyle}>{parse(about_content.text)}</Text>}
                        {about_content.list && (
                            <List layout="check">
                                {about_content.list.map((item, i) => (
                                    <ListItem key={`list-item-${i}`}>
                                        <span className="icon"><i className="fa fa-check"></i></span>
                                        <span>{item}</span>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        {about_content.link && (
                            <ButtonWrap>
                                <Button {...buttonStyle} iconname="far fa-long-arrow-right" to="/free-consultation">Let's get started</Button>
                            </ButtonWrap>
                        )}
                    </MissionContentWrap>
                </Col>
            </Row>
            {/* <ModalVideo
                channel='youtube'
                videoId={video_id}
                isOpen={videoOpen}
                onClose={modalVideoClose}
            /> */}
        </OurMissionWrap>
    );
}

OurMission.propTypes = {
    textStyle: PropTypes.object,
    buttonStyle: PropTypes.object
}

OurMission.defaultProps = {
    textStyle: {
        fontSize: '18px',
        mb: '30px'
    },
    buttonStyle: {
        varient: 'texted',
        fontWeight: 400,
        hover: "false"
    }
}

export default OurMission;