import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby"
import {Row, Col} from '../../../components/ui/wrapper'
import ValuesBox from '../../../components/box-image/layout-eight'
import {ValuesSection} from './values.style'
import BoxImage from '../../../components/box-image/layout-seven';

const Values = ({boxStyles}) => {
    const valuesQueryData = useStaticQuery(graphql `query ValuesDataQuery {
  allValuesJson {
    edges {
      node {
        id
        title
        desc
        image {
          childImageSharp {
            gatsbyImageData(width: 140, height: 110, quality: 100, layout: FIXED)
          }
        }
      }
    }
  }
}
`);
    
    const valuesData = valuesQueryData.allValuesJson.edges;

    return (
        <ValuesSection>
            <Row>
                {valuesData.map(award => (
                    <Col
                    lg={4}
                    md={6}
                    className="box-item"
                    key={`box-image-${award.node.id}`}
                  >
                    <BoxImage
                      imageSrc={award.node.image.childImageSharp}
                      title={award.node.title}
                      desc={award.node.desc}
                      //   path="/"
                    />
                    </Col>
                ))}
            </Row>
        </ValuesSection>
    )
}

Values.propTypes = {
    boxStyles: PropTypes.object
}

Values.defaultProps = {
    boxStyles: {
        boxStyle: {
            mt: '50px'
        }
    }
}

export default Values;