import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../containers/about-us/page-header'
//import PageHeader from '../components/pageheader'
import AboutArea from '../containers/about-us/about-area'
import ServicesArea from '../containers/about-us/services-area'
import ResourcesArea from '../containers/about-us/resources-area'
import SolutionsArea from '../containers/about-us/solutions-area'
import FunFactArea from '../containers/global/funfact-area/section-one'
import TestimonialArea from '../containers/global/testimonial-area/section-one'
import ClientsArea from '../containers/global/clients-area/section-one'
import ContactArea from '../containers/global/contact-area/contact-two'
import TabsArea from '../containers/tabs-area'

const seo = {
  title: "About Us",
  description: "We are in the business of your success.",
  image: "/dark-logo.png",
  pathname: "/about-us",
};

const AboutPage = ({pageContext, location}) => (
  <Layout location={location}>
    <SEO
      title={seo.title}
      description={seo.description}
      image={`${seo.image}`}
      pathname={seo.pathname}
    />
    <Header/>
    {/* <PageHeader 
      pageContext={pageContext} 
      location={location}
      title="About Us"
      /> */}
    <main className="site-wrapper-reveal">
        <PageHeader 
            pageContext={pageContext} 
            location={location}
            title="About Us"
        />
        <AboutArea/>
        <TabsArea/>
        <ServicesArea/>
        {/* <ResourcesArea/>
        <SolutionsArea/> */}
        <FunFactArea/>
        <TestimonialArea/>
        <ClientsArea/>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default AboutPage
 