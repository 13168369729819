import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Row, Col } from "../../../components/ui/wrapper";
import ValuesBox from "../../../components/box-image/layout-eight";
import { CultureSection } from "./culture.style";
import BoxImage from "../../../components/box-image/layout-seven";

const Culture = ({ boxStyles }) => {
  const cultureQueryData = useStaticQuery(graphql`query CultureDataQuery {
  allCultureJson {
    edges {
      node {
        id
        title
        desc
        image {
          childImageSharp {
            gatsbyImageData(width: 161, height: 110, quality: 100, layout: FIXED)
          }
        }
      }
    }
  }
}
`);

  const cultureData = cultureQueryData.allCultureJson.edges;

  return (
    <CultureSection>
      <Row>
        {cultureData.map(culture => (
          <Col
            lg={4}
            md={6}
            className="box-item"
            key={`box-image-${culture.node.id}`}
          >
            <BoxImage
              imageSrc={culture.node.image.childImageSharp}
              title={culture.node.title}
              desc={culture.node.desc}
              //   path="/"
            />
          </Col>
        ))}
      </Row>
    </CultureSection>
  );
};

Culture.propTypes = {
  boxStyles: PropTypes.object
};

Culture.defaultProps = {
  boxStyles: {
    boxStyle: {
      mt: "50px"
    }
  }
};

export default Culture;
